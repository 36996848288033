@import '../../../../scss/theme-bootstrap';

.gnav-tout {
  position: relative;
  &__inner {
    @include breakpoint($bp--largest-up) {
      flex-direction: column;
      display: flex;
    }
    & > * {
      width: 57%;
      float: $ldirection;
      @include breakpoint($bp--largest-up) {
        width: auto;
        float: none;
      }
    }
  }
  &__module-link,
  &__anchor-link {
    position: absolute;
    #{$ldirection}: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
  &__image {
    width: 43%;
    padding-#{$rdirection}: 10px;
    @include breakpoint($bp--largest-up) {
      width: auto;
      padding: 0;
    }
  }
  &__headline {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 1;
    font-family: $ano-bold_regular-font;
    @include breakpoint($bp--largest-up) {
      font-family: $ano-black_regular-font;
      font-size: 14px;
      line-height: 1.28571;
      margin: 15px 0;
    }
  }
  &__inner-content {
    font-size: 14px;
    @include breakpoint($bp--largest-up) {
      display: flex;
      flex-grow: 1;
      flex-flow: column;
      justify-content: space-between;
    }
  }
  &__copy p {
    @include breakpoint($bp--largest-up) {
      margin-bottom: 16px;
    }
  }
  &__link {
    @include breakpoint($bp--largest-up) {
      line-height: 1.3;
    }
  }
}
